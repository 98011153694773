import axios from "axios";
import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_SECRET_KEY;

const API = axios.create({
   baseURL: process.env.REACT_APP_API,
});

API.interceptors.request.use(
   async (config) => {
      try {
         const encryptedToken = localStorage.getItem("token");
         if (encryptedToken) {
            const decryptedToken = CryptoJS.AES.decrypt(
               encryptedToken,
               secretKey
            ).toString(CryptoJS.enc.Utf8);
            const token = JSON.parse(decryptedToken);


            if (token && !config.url.endsWith("/signIn")) {
               config.headers.Authorization = `Bearer ${token}`;
            }
         }
      } catch (error) {
         console.error(
            "Failed to decrypt token or attach authorization header:",
            error
         );
         // Optionally handle the error or continue without a token
      }

      return config;
   },
   async (error) => {
      return Promise.reject(error);
   }
);

API.interceptors.response.use(
   (response) => response,
   (error) => {
      if (error.response && error.response.status === 401) {
         const currentUrl = error.config.url; // Get the current request URL

         // Check if the request URL is not the login endpoint
         if (!currentUrl.endsWith("/signIn")) {
            // Clear the user and token from the Redux store
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("login");
            //   localStorage.removeItem("isAuthorized");

            // Redirect to the login page or perform any other necessary actions
            window.location.href = "/";
         }
      }
      return Promise.reject(
         (error.response && error.response.data) || "Something went wrong"
      );
   }
);

export default API;
