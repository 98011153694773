import { createSlice } from "@reduxjs/toolkit";
import API from "../../config/axios";
import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_SECRET_KEY;

const encryptedToken = localStorage.getItem("token");
const encryptedUserId = localStorage.getItem("userId");
const encryptedRoleName = localStorage.getItem("rolename");
const encryptedPrivileges = localStorage.getItem("previleges");
const isAuthenticated = localStorage.getItem("isAuthenticated");

let token, userId, roleName, privileges;

if (
   encryptedToken &&
   encryptedUserId &&
   encryptedRoleName &&
   encryptedPrivileges &&
   isAuthenticated
) {
   // Decrypt the data
   token = CryptoJS.AES.decrypt(encryptedToken, secretKey).toString(
      CryptoJS.enc.Utf8
   );
   userId = CryptoJS.AES.decrypt(encryptedUserId, secretKey).toString(
      CryptoJS.enc.Utf8
   );
   roleName = CryptoJS.AES.decrypt(encryptedRoleName, secretKey).toString(
      CryptoJS.enc.Utf8
   );
   privileges = CryptoJS.AES.decrypt(encryptedPrivileges, secretKey).toString(
      CryptoJS.enc.Utf8
   );

   // Dispatch the login success action with the decrypted data
   // dispatch(
   //    userLoginSuccess({
   //       token: JSON.parse(token),
   //       user: {
   //          roleID: JSON.parse(roleName),
   //          userId: JSON.parse(userId),
   //          roleName: JSON.parse(roleName),
   //          privileges: JSON.parse(privileges),
   //       },
   //    })
   // );
}

const initialState = {
   token: token?JSON.parse(token): null,
   isAuthenticated: isAuthenticated||false,
   rolename:roleName? JSON.parse(roleName):null,
   previleges: privileges?JSON.parse(privileges):null,
   userId:userId?JSON.parse(userId):null,
   isLoading: false,
   error: null,
};

const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {
      userLoginSuccess(state, action) {
         const { token, user } = action.payload;
         const { userId, roleName, privileges } = user;
         state.isAuthenticated = true;
         state.token = token;
         state.rolename = roleName;
         state.previleges = privileges;
         state.userId = userId;

         // Encrypt the data
         const encryptedToken = CryptoJS.AES.encrypt(
            JSON.stringify(token),
            secretKey
         ).toString();
         const encryptedUserId = CryptoJS.AES.encrypt(
            JSON.stringify(userId),
            secretKey
         ).toString();
         const encryptedRoleName = CryptoJS.AES.encrypt(
            JSON.stringify(roleName),
            secretKey
         ).toString();
         const encryptedPrevilage = CryptoJS.AES.encrypt(
            JSON.stringify(privileges),
            secretKey
         ).toString();

         localStorage.setItem("token", encryptedToken);
         localStorage.setItem("previleges", encryptedPrevilage);
         localStorage.setItem("rolename", encryptedRoleName);
         localStorage.setItem("userId", encryptedUserId);
         localStorage.setItem("isAuthenticated", true);
      },
      userLoginFailure(state, action) {
         state.isAuthenticated = false;
         state.error = action.payload;
      },
      userLogout(state) {
         state.token = null;
         state.isAuthenticated = false;
         state.rolename = null;
         state.previleges = null;
         state.userId = null;
         state.isLoading = false;
         state.error = null;
         localStorage.clear();
      },
   },
});

export const { userLoginSuccess, userLoginFailure, userLogout } =
   authSlice.actions;
export default authSlice.reducer;

export function userSignIn({ email, password }) {
   return async (dispatch) => {
      // const navigate = useNavigate();
      try {
         const response = await API.post(`/user/signIn`, {
            email,
            password,
         });

         if (response.data && response.data.status === "success") {
            dispatch(userLoginSuccess(response.data));
            // navigate("/dashboard")
         } else {
            dispatch(userLoginFailure("Invalid credentials"));
         }
      } catch (error) {
         console.error("error", error);
         dispatch(userLoginFailure(error?.message||error.response?.data?.message||"Something went wrong"));
      }
   };
}

export function loadUserFromStorage() {
   return (dispatch) => {
      try {
         const encryptedToken = localStorage.getItem("token");
         const encryptedUserId = localStorage.getItem("userId");
         const encryptedRoleName = localStorage.getItem("rolename");
         const encryptedPrivileges = localStorage.getItem("previleges");
         const isAuthenticated = localStorage.getItem("isAuthenticated");

         if (
            encryptedToken &&
            encryptedUserId &&
            encryptedRoleName &&
            encryptedPrivileges &&
            isAuthenticated
         ) {
            // Decrypt the data
            const token = CryptoJS.AES.decrypt(
               encryptedToken,
               secretKey
            ).toString(CryptoJS.enc.Utf8);
            const userId = CryptoJS.AES.decrypt(
               encryptedUserId,
               secretKey
            ).toString(CryptoJS.enc.Utf8);
            const roleName = CryptoJS.AES.decrypt(
               encryptedRoleName,
               secretKey
            ).toString(CryptoJS.enc.Utf8);
            const privileges = CryptoJS.AES.decrypt(
               encryptedPrivileges,
               secretKey
            ).toString(CryptoJS.enc.Utf8);

            // Dispatch the login success action with the decrypted data
            dispatch(
               userLoginSuccess({
                  token: JSON.parse(token),
                  user: {
                     roleID: JSON.parse(roleName),
                     userId: JSON.parse(userId),
                     roleName: JSON.parse(roleName),
                     privileges: JSON.parse(privileges),
                  },
               })
            );
         }
      } catch (error) {
         console.error("Failed to load user from storage", error);
         dispatch(userLoginFailure("Failed to load user from storage"));
      }
   };
}
