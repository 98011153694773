import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


const ProtectedRoute = () => {
  console.log("protected")
    const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
    // const isAuthenticated= localStorage.getItem('isAuthenticated')
    if (isAuthenticated) return <Outlet />;
  
    return <Navigate to={"/auth/sign-in"} />;
  };
  
  export default ProtectedRoute;