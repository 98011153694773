import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import API from "../../config/axios";

export const getAllMessagesByChatId = createAsyncThunk(
   "messages/getAllMessagesByChatId",
   async ({ chatId, page }) => {
      try {
         const response = await API.get(`/messages/${chatId}?page=${page}`);

         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);
export const loadMoreChatByChatId = createAsyncThunk(
   "messages/loadMoreChatByChatId",
   async ({ chatId, page }) => {
      try {
         const response = await API.get(`/messages/${chatId}?page=${page}`);
         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);

export const getAllMessagesByGroupId = createAsyncThunk(
   "messages/getAllMessagesByGroupId",
   async ({ groupId, page }) => {
      try {
         const response = await API.get(`/messages/${groupId}?page=${page}`);

         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);

const initialState = {
   selectedChat: {},
   newMessages: {}, // State for storing newly received messages
   messages: [], // State for storing inital loaded messages
   loadedMessage: [], // State for storeing message if load more clicked
   nonReadMessageCount: 0,
   page: 1,
   loading: false,
   error: "",
   clicked: false,
   totalMessage: 0,
};

const messageSlice = createSlice({
   name: "messages",
   initialState,
   reducers: {
      saveSelectedChat(state, action) {
         state.selectedChat = action.payload;
      },
      // updateSentMessage(state, action) {
      //    const newMessage = action.payload;
      //    // state.messages.unshift(newMessage)
      //    if (state.newMessages.length > 0) {
      //       state.newMessages = [...state.newMessages, newMessage];
      //    } else {
      //       state.messages = [...state.messages, newMessage];
      //    }
      // },
      // updateSentMessage(state, action) {
      //    const newSendMessage = action.payload;
      //    const today = moment().format("YYYY-MM-DD");
      //    if (state.newMessages && state.newMessages.messages?.length > 0) {
      //       const existMessage = state.newMessages.messages;
      //       state.newMessages.messages = [...existMessage, newSendMessage];
      //       // // state.newMessages = [...state.newMessages, newSendMessage];
      //       // let messages = state.newMessages;
      //       // // Find the object with today's date in the "id" field
      //       // const todayObjectIndex = messages.findIndex(
      //       //    (obj) => obj._id === today
      //       // );

      //       // if (todayObjectIndex !== -1) {
      //       //    // Today's date object exists, add the new message to its messages array
      //       //    messages[todayObjectIndex].messages.push(newSendMessage);
      //       // } else {
      //       //    // Today's date object doesn't exist, create a new object and add it to the messages
      //       //    messages.push({
      //       //       _id: today,
      //       //       messages: [newSendMessage],
      //       //    });
      //       // }
      //       // state.newMessages = messages;
      //    } else {
      //       // state.newMessages = {
      //       //    _id: today,
      //       //    messages: [newSendMessage],
      //       // };
      //       let messages = state.messages;
      //       // Find the object with today's date in the "id" field
      //       const todayObjectIndex = messages.findIndex(
      //          (obj) => obj._id === today
      //       );

      //       if (todayObjectIndex !== -1) {
      //          // Today's date object exists, add the new message to its messages array
      //          messages[todayObjectIndex].messages.push(newSendMessage);
      //       } else {
      //          // Today's date object doesn't exist, create a new object and add it to the messages
      //          messages.push({
      //             _id: today,
      //             messages: [newSendMessage],
      //          });
      //       }
      //       state.messages = messages;
      //    }
      // },
      updateNewMessage(state, action) {
         const { newMessage } = action.payload || {};
         if (Object.keys(newMessage).length > 0) {
            let existingMessages = state.newMessages?.messages || [];
            if (existingMessages.length > 0) {
               existingMessages = [...existingMessages, newMessage];

               state.newMessages.messages = existingMessages;
            } else {
               const newMsgObject = {
                  _id: moment().format("YYYY-MM-DD"),
                  messages: [newMessage],
               };
               state.newMessages = newMsgObject;
            }
         } else {
            // Handle the case of empty newMessage (no new messages)
         }
      },
      // updateNewMessage(state, action) {
      //    const { newMessage } = action.payload || {};
      //    const today = moment().format("DD-MM-YYYY");
      //       let messages = state.newMessages;
      //       // Find the object with today's date in the "id" field
      //       const todayObjectIndex = messages.findIndex(
      //          (obj) => obj._id === today
      //       );

      //       if (todayObjectIndex !== -1) {
      //          // Today's date object exists, add the new message to its messages array
      //          messages[todayObjectIndex].messages.push(newMessage);
      //       } else {
      //          // Today's date object doesn't exist, create a new object and add it to the messages
      //          messages.push({
      //             _id: today,
      //             messages: [newMessage],
      //          });
      //       }
      //       state.newMessages = messages;
      // },
      //Reducer for updating the message status of specific message if the status changed
      // updateMessageStatus(state, action) {
      //    const messageId = action.payload?.message.id;
      //    const status = action.payload.status;
      //    const message = state.messages;
      //    const newMsg = state.newMessages;
      //    const loadMsg = state.loadedMessage;

      //    if (message.length > 0) {
      //       state.messages = message.map((msg) => {
      //          const msgId = msg.message?.id;
      //          if (msgId === messageId) {
      //             msg.status = status;
      //          }
      //          return msg;
      //       });
      //    }
      //    if (newMsg.length > 0) {
      //       state.newMessages = newMsg.map((msg) => {
      //          const msgId = msg.message?.id;

      //          if (msgId === messageId) {
      //             msg.status = status;
      //          }
      //          return msg;
      //       });
      //    }
      //    if (loadMsg.length > 0) {
      //       state.loadedMessage = loadMsg.map((msg) => {
      //          const msgId = msg.message?.id;
      //          if (msgId === messageId) {
      //             msg.status = status;
      //          }
      //          return msg;
      //       });
      //    }
      // },
      updateMessageStatus(state, action) {
         const messageIdToUpdate = action.payload?._id;
         const newStatus = action.payload.status;
         const message = state.messages;
         const newMsg = state.newMessages;
         const loadMsg = state.loadedMessage;

         if (message.length > 0) {
            // Iterate through messagesData to find the messageObject with the matching ID
            for (let i = 0; i < message.length; i++) {
               let messagesOfDay = message[i].messages;
               for (let j = 0; j < messagesOfDay.length; j++) {
                  if (messagesOfDay[j]._id === messageIdToUpdate) {
                     messagesOfDay[j].status = newStatus;
                     break; // Assuming each messageObject has a unique ID, we can break early
                  }
               }
            }
            state.messages = message;
         }
         if (newMsg && newMsg.messages?.length > 0) {
            const updatedMessages = newMsg.messages.map((message) => {
               if (message._id === messageIdToUpdate) {
                  return { ...message, status: newStatus };
               } else {
                  return message;
               }
            });

            state.newMessages = { ...newMsg, messages: updatedMessages };
         }
         if (loadMsg.length > 0) {
            for (let i = 0; i < loadMsg.length; i++) {
               let messagesOfDay = loadMsg[i].messages;
               for (let j = 0; j < messagesOfDay.length; j++) {
                  if (messagesOfDay[j]._id === messageIdToUpdate) {
                     messagesOfDay[j].status = newStatus;
                     break; // Assuming each messageObject has a unique ID, we can break early
                  }
               }
            }
            state.loadedMessage = loadMsg;
         }
      },
      updatePageNumber(state) {
         state.page = state + 1;
      },
      setClicked(state, action) {
         state.clicked = action.payload;
      },
      setNewMessageEmpty(state, action) {
         state.newMessages = [];
      },
      setMessageEmpty(state) {
         state.messages = [];
      },
      setLoadedMessageEmpty(state) {
         state.loadedMessage = [];
      },
      resetMessageState(state) {
         state.loadedMessage = [];
         state.newMessages = [];
         state.messages = [];
         state.messageCount = 0;
         state.nonReadMessageCount = 0;
         state.page = 1;
      },
      updateReceivedMsgTime(state, action) {
         // Ensure a copy to avoid mutation
         const chat = state.selectedChat;

         // Update or create the field using spread operator with optional chaining
         state.selectedChat = {
            ...chat,
            last_msg_received_at: action.payload,
         };
      },
   },

   extraReducers: (builder) => {
      builder
         .addCase(getAllMessagesByChatId.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllMessagesByChatId.fulfilled, (state, action) => {
            state.loading = false;
            state.messages = action.payload.messages;
            state.nonReadMessageCount = action.payload.messageCount;
            state.totalMessage = action.payload.totalMessage;
            // state.clicked = false;
         })
         .addCase(getAllMessagesByChatId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         })
         .addCase(loadMoreChatByChatId.pending, (state) => {
            state.loading = true;
         })
         .addCase(loadMoreChatByChatId.fulfilled, (state, action) => {
            state.loading = false;
            const { page } = action.payload;
            // Append new messages to existing ones for pagination
            state.loadedMessage = [
               ...action.payload.messages,
               ...state.loadedMessage,
            ];
            // state.messages = [...action.payload.messages, ...state.messages];
            state.page = Number(page);
         })
         .addCase(loadMoreChatByChatId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         })
         .addCase(getAllMessagesByGroupId.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllMessagesByGroupId.fulfilled, (state, action) => {
            state.loading = false;
            state.messages = action.payload.messages;
         })
         .addCase(getAllMessagesByGroupId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },
});

export default messageSlice.reducer;

export const {
   saveSelectedChat,
   updateNewMessage,
   updatePageNumber,
   setClicked,
   setNewMessageEmpty,
   updateSentMessage,
   setMessageEmpty,
   resetMessageState,
   updateMessageStatus,
   updateReceivedMsgTime,
} = messageSlice.actions;
