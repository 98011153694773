import { grey } from "@mui/material/colors";
import typography from "./Typography";

export const getDesignTokens = (mode) => ({
   palette: {
      mode,
      ...(mode === "light"
         ? {
              // palette values for light mode
              primary: {
                 main: "#0292d0",
                 iconmain: "#0292d0",
              },
              divider: "#E4E4E4",
              text: {
                 primary: "#000000",
                 bulkgreen: "#02D223",
                 secondary: grey[800],
                 greytext: grey,
                 grey: "#414042",
                 block: "#ff2c2c",
                 lightgrey: "#666666",
                 whitetext: "#fff",
                 greentext: "#009b07",
                 toggletext: "#252525",
                 chartext: "#7c7c7c",
                 wpcount: "#fff",
                 tableheadtext: "#0292d0",
                 tablebodytext: "#000",
                 replytext: "#0292d0",
                 darkgrey: "#404040",
                 sidebaractive: "#002539",
                 graphheading: "#515151",
              },
              background: {
                 primary: "#fff",
                 body: "#f9f9f9",
                 modal: "#ffffff",
                 skeleton: "#e6e6e6",
                 lightblue: "#ebf4fb",
                 profilebg: "#d9d9d9",
                 profilebgchat: "#dddddd",
                 backgroundgrey: "#f5f5f7",
                 backgroundblue: "#0292d0",
                 whatsappbody: "#ffffff",
                 whatsappsearch: "#f4f4f6",
                 incomingmsg: "#f4f4f6",
                 outgoingmsg: "#0293d2",
                 outgoingbtn: "#006b99",
                 outgoingbtnHover: "#0293d2",
                 datebox: "#e7e7e7",
                 sidebar: "#ffffff",
                 navbar: "#f4f4f6",
                 tablehead: "#ebf4fb",
                 tablebody: "#fff",
                 bgbutton: "#fff",
                 chatinput: "#fdfdfd",
                 iconhover: "#0287c1",
                 cardbox: "#fff",
                 replybox: "#ecf4fb",
                 selectedchat:"#0293d2",
                 template:"#faedff",
                 image:"#FFF0EB",
                 file:"#ecf4ff",
                 music:"#ffebf7",
                 dashboardcards:
                    "linear-gradient(118deg, rgba(255, 255, 255, 0.80) 15%, rgba(255, 255, 255, 0.50) 70%)",
              },
              border: {
                 wpdivider: "#F1F1F1",
                 tablebottom: "#e4e4e49e",
                 buttonborder: "#bbbbbb",
                 skeletondivider: "#f1f1f185",
                 blueborder: "#0293d2",
                 clearborder: "#fbbbbb",
              },
              button: {
                 buttons: "#0293d2",
                 buttonshover: "#0287c1",
                 syncbg: "#e6fbe9",
                 syncHoverBg: "#cbf9d1",
                 synctext: "#02d223",
                 pendingbg: "#fbf3e8",
                 pendingtext: "#FFB215",
                 pendingHoverBg: "#fbecd8",
                 rejectedbg: "#ffcfcb",
                 rejectedtext: "#f91c0c",
                 rejectedHoverBg: "#ffb9b3",
                 sendbtn: "#0293d2",
                 backgroundhover: "#e3e3e3",
                 texthover: "#e3e3e3",
                 clearbutton: "#f9f9f9",
                 clearbtnhover: "#fbbbbb",
              },
              switch: {
                 bgcolor: "#02d223",
              },
              dateRangePicker: {
                 backgroundColor: "#fff",
                 borderColor: "#ddd",
                 textColor: "#000",
              },
           }
         : {
              // palette values for dark mode
              primary: {
                 main: "#fff",
                 iconmain: "#0292d0",
              },
              divider: "#282828",
              background: {
                 primary: "#202020",
                 body: "#2c2c2c",
                 modal: "#2c2c2c",
                 skeleton: "#353535",
                 lightblue: "#002232",
                 profilebg: "#757575",
                 profilebgchat: "#414141",
                 backgroundblue: "#181818",
                 backgroundgrey: "#181818",
                 whatsappbody: "#2c2c2c",
                 whatsappsearch: "#222222",
                 incomingmsg: "#353535",
                 outgoingmsg: "#003650",
                 outgoingbtn: "#001c2a",
                 outgoingbtnHover: "#003650",
                 datebox: "#818181",
                 sidebar: "#202020",
                 navbar: "#222222",
                 tablehead: "#181818",
                 tablebody: "#222222",
                 bgbutton: "#222222",
                 chatinput: "#353535",
                 iconhover: "#006693",
                 cardbox: "#1e1e1e",
                 replybox: "#1c1c1c",
                 selectedchat:"#383838",
                 template:"#290038",
                 image:"#581500",
                 file:"#0D1F39",
                 music:"#410629",
                 dashboardcards:
                    "linear-gradient(71deg, #080509, #1a171c, #080509)",
              },
              text: {
                 primary: "#fff",
                 secondary: grey[500],
                 bulkgreen: "#02D223",
                 greytext: "#fff",
                 grey: "#a7a7a7",
                 block: "#ff2c2c",
                 lightgrey: "#fff",
                 whitetext: "#fff",
                 greentext: "#009b07",
                 wpcount: "#fff",
                 tableheadtext: "#fff",
                 tablebodytext: "#fff",
                 replytext: "#0292d0",
                 toggletext: "#fff",
                 chartext: "#7c7c7c",
                 darkgrey: "#afafaf",
                 sidebaractive: "#0293D2",
                 graphheading: "#fff",
              },
              border: {
                 wpdivider: "#222222",
                 tablebottom: "#000",
                 buttonborder: "#3e3e3e",
                 skeletondivider: "#222222",
                 blueborder: "#0293d2",
                 clearborder: "#290002",
              },
              button: {
                 buttons: "#0293d2",
                 buttonshover: "#003650",
                 syncbg: "#014a0c;",
                 syncHoverBg: "#003007",
                 synctext: "#00d922",
                 pendingbg: "#462900",
                 pendingtext: "#ffab00",
                 pendingHoverBg: "#382100",
                 rejectedbg: "#600e07",
                 rejectedtext: "#ff1100",
                 rejectedHoverBg: "#400500",
                 sendbtn: "#006e9f",
                 backgroundhover: "#2c2c2c",
                 texthover: "#484848",
                 audio: "#4c4c4c",
                 clearbutton: "#290002",
                 clearbtnhover: "#1a0303",
              },
              switch: {
                 bgcolor: "#02d223",
              },
              dateRangePicker: {
                 backgroundColor: "#333",
                 borderColor: "#444",
                 textColor: "#fff",
              },
           }),
   },
   typography: typography,
   components: {
      MuiOutlinedInput: {
         styleOverrides: {
            notchedOutline: {
               borderColor: mode === "light" ? grey[400] : "#3e3e3e", // Default border color
            },
            root: {
               borderRadius: "5px",
               "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0293d2", // Border color on hover
               },
               "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0293d2", // Border color when focused
               },
               "&.Mui-focused + .MuiInputLabel-root": {
                  color: "#0293d2", // Legend color when focused
               },
            },
         },
      },

      MuiInputLabel: {
         styleOverrides: {
            root: {
               "&.Mui-focused": {
                  color: mode === "light" ? "#0293d2" : "#ffffff", // Legend color when focused
               },
            },
         },
      },
      MuiFormControl: {
         styleOverrides: {
            root: {
               background: mode === "light" ? "#fff" : "#222222",
               borderRadius: "5px",
            },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               borderRadius: "8px",
               textTransform: "none",
               padding: "6px 16px",
               //  backgroundColor: "#0292d0",
               color: "#ffffff",
               fontWeight: 500,
               "&:hover": {
                  backgroundColor: "#027bb1",
               },
               "&.Mui-disabled": {
                  backgroundColor: "#b0b0b0",
                  color: "#ffffff",
               },
            },
            outlinedPrimary: {
               color: "#0293d2",
               border: "0.5px solid #0293d2",
               "&:hover": {
                  backgroundColor: "#0293d2",
                  color: "#fff",
                  "& .MuiButton-startIcon": {
                     color: "#fff",
                  },
               },
            },
            containedPrimary: {
               backgroundColor: "#0292d0",
               fontWeight: 500,
               "&:hover": {
                  backgroundColor: "#027bb1",
               },
            },
            containedSecondary: {
               backgroundColor: "#b2b2b2",
               "&:hover": {
                  backgroundColor: "#a0a0a0",
               },
            },
         },
      },
   },
});
